body {
    background-color: #66ba87;
    font-family: "IBM Plex Sans";
    color: #473D63;
}

.headliners {
    margin: 0;
}

.headliner-artist, .headliner-more {
    margin: 0 0.5%;
    display: inline-block;
    b {
        font-size: 140%;
        font-weight: 700;
        a{
            color: #3B427A;
            text-decoration: none;
            &:hover{
                color: #eb5c38;
            }
        }       
    }
}

.artist-list-h {
    margin: 5vh 0;
}

.artist-list {
    padding: 1vh 0;
    border-bottom: 3px dashed #3B427A;
    .artist-list-img{
        width: 80%;
    }
    span {
        b{
            margin: 0 1%;
            font-size: 140%;
        }
        a{
            color: #3B427A;
            text-decoration: none;
            &:hover{
                color: #eb5c38;
            }
        }  
        
    }
}

.artist-detail{
    img{
        width: 100%;
    }
}

.artist-timeslots{
    font-size: 100%;
    font-weight: bolder;
    display: block;
}

.venue-header{
    text-align: center;
    text-transform: uppercase;
    background-color: #39a3a8;
    color: white;
    border-radius: 4px;
    padding: 5px 0;
    @media only screen and (max-width: 991px) {
        min-height: 8vh;
        max-height: 8vh;
        margin: 50px 0 0 0;
    }
}

.logowrapper{
    flex-basis: 10%;
    @media only screen and (max-width: 991px) {
        flex-basis: 20%;
    }
}

.venue-timetable {
    width: 100%;
}

.timetable{
    margin: 5px auto;
}

.no-padding{
    padding: 0 !important;
}

.time-tag, .artist-tag{
    padding: 0 0.25vh !important;
    span {
        margin: 1vh 0 !important;
        //padding: 16px 6px;
        height: 6vh;
        color: white;
        border-radius: 8px;
        display: block;
        text-align: center;
        a, .tag, .nolink {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            margin: auto;
            text-decoration: none;
            color: inherit;
            text-transform: uppercase;
        }
    }
}

.artist-tag{
    .headliner{
        background-color: #3B427A;
    }
    .no-headliner{
        background-color: #525B8F;
    }
    .headliner, .no-headliner{

        @media only screen and (max-width: 991px) {
            font-size: 85%;
        }

        .mobile-time-tag{
            display: flex;
            align-items: center;
            justify-content: center;
            left: 10px;
            padding: 1vh 4vw 1vh 0 !important;
            font-size: 80%;
        }
    }
}

.gallery-elem{
    border-radius: 5px;
    margin: 5px;
    padding: 0;
    span{
        background-color:#4FA2A8;
        color: white;
        width: 90%;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 1vh 1vw;
        a{
            text-transform: uppercase;
            text-decoration: none;
            color: white;
            font-size: 110%;
            margin-right: 30px;
        }
    }
}

.day-tag{
    span{
        background-color: #EB5952;
        text-transform: uppercase;
        font-weight: normal !important;
    }
}

.time-tag{
    span{
        background-color: #4FA2A8;
        font-weight: bold;
    }
}

.policy{
    margin: 5vh 0;
    a{
        color: #473D63;
        text-decoration: none;
        &:hover{
            color: #eb5c38;
        }
    }  
}

.mobile-date{ 
    border-top: 3px dashed #473D63;  
    border-bottom: 3px dashed #473D63; 
    //background-color: #e44f49;
    text-transform: uppercase;
    font-weight: normal !important;
    text-align: center;
    color: #473D63;
    padding: 2vh 0;
    border-radius: 1px;
    margin: 1vh 0;
    font-weight: bolder;
}


.voting-block{
    a {
        text-decoration: none;
        color: white;
        text-transform: uppercase;
        background-color: #ee734c;
        text-align: center;
        padding: 1vh 5vw;
        border-radius: 5px;
        font-weight: 700;
        font-size: 120%;
        margin: 2vh 2vw;
    }
}

.artist-voting-block{
    margin-top: 10vh;
}

.voting-block-link{

    text-decoration: none;
    color: white;
    text-transform: uppercase;
    background-color: #ee734c;
    text-align: center;
    padding: 1vh 3vw;
    border-radius: 5px;
    font-weight: 700;
    font-size: 120%;
    margin: 4vh 0;
}

.promo {
    color: #473D63;
    padding: 5vh 3vw;
    font-size: 110%;
    font-weight: 800;
    a {
        color: #473D63;
    }
    p {
        text-align: left;
    }
}